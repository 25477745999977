// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
// import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import { RailsUJSController } from 'stimulus-rails-ujs'
// import Sortable from 'stimulus-sortable'
import Sortable from "./sortable_controller"
import { Autocomplete } from "stimulus-autocomplete"

const application = Application.start()
application.register('rails-ujs', RailsUJSController)
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
// StimulusReflex.initialize(application, { consumer })
application.register('sortable', Sortable)
application.register("autocomplete", Autocomplete)
